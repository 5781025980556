.bold {
  font-weight: bold; }

.italic {
  font-style: italic; }

.underline {
  text-decoration: underline; }

.code {
  color: #333;
  background: #eee;
  padding: 4px;
  border-radius: 4px; }

.h1 {
  font-size: 1.75rem;
  font-weight: 600; }

.h2 {
  font-size: 1.5rem;
  font-weight: 600; }

.h3 {
  font-size: 1.25rem;
  font-weight: 600; }
