.unselectable {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  // Introduced in IE 10.  See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
  -ms-user-select: none;
  user-select: none; }

.center {
  text-align: center;
  margin-left: auto;
  margin-right: auto; }

.text-center {
  text-align: center; }

.hidden {
  display: none;
  visibility: hidden; }

.offscreen {
  position: absolute;
  left: -999999em; }

// http://stackoverflow.com/questions/218760/how-do-you-keep-parents-of-floated-elements-from-collapsing
.clearfix:after {
  content: " ";
  display: block;
  height: 0;
  clear: both; }

.tooltip {
  position: relative;
  cursor: default;

  &:hover {
    color: #c00;
    text-decoration: none; }

  &:hover:after {
    background: #111;
    background: rgba(0,0,0,.6);
    border-radius: .5em;
    bottom: 1em;
    color: #fff;
    content: attr(title);
    display: block;
    text-align: center;
    padding: .3em 1em;
    position: absolute;
    width: '100%';
    z-index: 98;
    font-size: 12px; } }

.text-success {
  color: green; }

.text-error {
  color: red; }
