#view {

    table {
        border-collapse: collapse;
        td, th {
            padding: 5px; } } }

.block {
    $indent-padding: 24px;
    padding-left: $indent-padding;

    .bullet {
        float: left;
        position: relative;
        display: inline-block;
        margin-left: - $indent-padding / 2 - 5;

        &.clone-icon {
            margin-left: - $indent-padding - 5; } }

    .bullet-item {
        float: left;
        display: inline-block;
        margin-right: 5px;
        cursor: pointer; } }


.node {
    $node-min-height: 20px;
    width: 860px;
    margin: auto;

    // min height should be at least font size
    min-height: $node-min-height;
    font-size: 16px;

    .node-text {
        margin-left: 8px;
        display: block;
        overflow-wrap: break-word;
        white-space: pre-wrap;
        min-height: $node-min-height;
        line-height: 1.5em; } }

.searchBox {
    white-space: pre-wrap;
    padding: 10px;
    margin-bottom: 20px; }

.hotkey {
    padding: 2px;
    border-radius: 5px; }

@keyframes blink {
    50% {
        visibility: hidden; } }

.blink-background {
    animation: blink .5s step-end infinite alternate; }
